import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["chakra", "progressBar"]

  calculateSum() {
    let sum = 0
    this.chakraTargets.forEach((chakra) => {
      sum += parseInt(chakra.value);
    });

    return sum
  }

  updateProgressBar() {
    let progressContent = "";
    const sum = this.calculateSum();

    this.chakraTargets.forEach((chakra) => {
      const percentValue = chakra.value / sum * 100
      progressContent += `<div  class="progress-bar ${chakra.dataset.name}"
                                role="progressbar"
                                style="width: ${percentValue}%"
                                aria-valuenow="${percentValue}"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                data-controller="tooltip"
                                data-bs-toggle="tooltip"
                                data-bs-title="${chakra.dataset.name}">
                          </div>`;
    });

    this.progressBarTarget.innerHTML = progressContent;
  }
}
