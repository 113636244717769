import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class extends Controller {
  connect() {
    new Choices(this.element, {
      searchEnabled: false,
      searchChoices: false,
      addItems: false,
      placeholder: true,
      placeholderValue: this.element.dataset.placeholder,
      itemSelectText: "",
      removeItemButton: true,
      shouldSort: false
    });
    // workaround to keep a blank option in the dropdown to allow
    // submission without selecting anything but hide it to the user
    const optionBlank = document.querySelectorAll('.choices__list [data-id="1"]');
    optionBlank.forEach(option => option.classList.add("d-none"));
  }
}
