import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slider", "numberLegend"]
  static values = { initialStep: Number }

  connect() {
    this.sliderTarget.value = this.initialStepValue;
  }

  updateSliderValue() {
    this.numberLegendTarget.innerText = this.sliderTarget.value;
  }
}
